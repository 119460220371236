<template>
  <div class="illustration">
    <img :src="imageUrl" :alt="imageAltText" class="picture" />
  </div>
</template>

<script>
  export default {
    props: {
      imageUrl: {
        type: String,
        default: null,
      },
      imageAltText: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="scss">
  .illustration {
    width: 100%;
  }

  .picture {
    display: block;
    height: 100%;
    width: 100%;

    @media print {
      display: none;
    }
  }
</style>
