<template>
  <Container space-before space-after>
    <Illustration :image-url="imageUrl" :image-alt-text="imageAltText" />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      imageIngredient() {
        return this.getIngredient("image") || {}
      },
      imageUrl() {
        return this.imageIngredient.value
      },
      imageAltText() {
        return this.imageIngredient.altText
      },
    },
  }
</script>
